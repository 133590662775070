
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      filterCategorySlugs: [],
      articles: [],
      requestSource: null,
    }
  },

  async fetch() {
    await this.$store.dispatch('taxonomy/loadArticleCategoryTaxonomy')
  },

  computed: {
    isLastPage() {
      return this.currentPage >= this.articles.last_page
    },

    isFirstPage() {
      return this.currentPage <= 1
    },

    currentPage() {
      return this.articles.current_page
    },

    pages() {
      return [...Array(this.articles.last_page).keys()]
    },
  },

  watch: {
    'data.articles'() {
      this.articles = this.data.articles
    },
  },

  mounted() {
    this.articles = this.data.articles
    const filterCategories = this.$route.query.filter_categories?.split(',')
    if (filterCategories) {
      this.filterCategorySlugs = filterCategories
    }
  },

  methods: {
    filterArticles(selectedDepartments) {
      this.filterCategorySlugs = selectedDepartments.map((d) => d.slug)
      this.loadPage(1)
    },

    async loadPage(page = 1) {
      // cancel pending request
      if (this.requestSource) {
        this.requestSource.cancel()
      }

      this.requestSource = this.$axios.CancelToken.source()

      const filter = encodeURI(this.filterCategorySlugs.join(','))
      let url = `website/entries${this.data.uri}?page=${page}`

      if (filter) {
        url += `&filter_categories=${filter}`
      }

      try {
        const { data } = await this.$axios.$get(url, {
          cancelToken: this.requestSource.token,
        })
        this.articles = data.articles

        if (filter) {
          this.$router.push({
            query: {
              ...this.$route.query,
              filter_categories: filter,
            },
          })
        } else {
          const query = { ...this.$route.query }
          delete query.filter_categories
          this.$router.push({
            query,
          })
        }
      } catch (error) {
        if (this.$axios.isCancel(error)) {
          console.log('Request canceled', error)
        } else {
          this.$toasted.error(this.$t('error'))
        }
      }
    },

    scrollTo(page, currentPage) {
      if (page !== currentPage) {
        this.$emit('scroll-to')
      }
    },
  },
}
