
export default {
  props: {
    member: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isHover: false,
    }
  },

  computed: {
    memberDepartments() {
      const departments = this.member.departments.map(
        (department) => department.title
      )
      if (!departments.length) {
        return null
      }

      if (departments.length === 1) {
        return departments[0]
      }

      return departments.join(' | ')
    },
  },
}
