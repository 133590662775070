
import { reactive } from '@nuxtjs/composition-api'
import SeoFunctions from '~/mixins/SeoFunctions'

const passQueryParams = ['filter_categories']

/**
 * Some query params should be pased to fetch page.
 * Here you can add the names of them to `passQueryParams` to pass the with the api request.
 */
function getQuery(query) {
  if (!query) {
    return null
  }

  const queryParams = Object.entries(query).filter(([key]) => {
    return passQueryParams.includes(key)
  })

  return new URLSearchParams(queryParams).toString()
}

export default {
  mixins: [SeoFunctions],

  provide() {
    return {
      page: reactive(this.data),
    }
  },

  async asyncData({ route, $axios, error, redirect }) {
    const query = getQuery(route.query)
    try {
      const { data } = await $axios.$get(
        `website/entries${route.path}?${query}`
      )
      return {
        data,
      }
    } catch (err) {
      if (err.response.status === 404) {
        // try for redirect
        try {
          const { data } = await $axios.$get(
            `website/redirects${route.path}?${query}`
          )

          redirect(data.type, data.destination)
        } catch (redirectError) {}
      }

      error({
        statusCode: err.response?.status || 500,
        message: err.response?.data?.message || 'Unknown error occurred',
      })
    }
  },

  head() {
    return this.data ? this.getHeadForPage(this.data) : {}
  },
}
