
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      filterDepartmentSlugs: [],
    }
  },

  computed: {
    filteredTeamMembers() {
      if (!this.filterDepartmentSlugs.length) {
        return this.data.team_members
      }

      return this.data.team_members.filter((member) =>
        member.departments.some((d) =>
          this.filterDepartmentSlugs.includes(d.slug)
        )
      )
    },
  },

  methods: {
    filterTeamMembers(selectedDepartments) {
      this.filterDepartmentSlugs = selectedDepartments.map((d) => d.slug)
    },
  },
}
