import { render, staticRenderFns } from "./TeamMemberPage.vue?vue&type=template&id=7be2dccc&scoped=true&"
import script from "./TeamMemberPage.vue?vue&type=script&lang=js&"
export * from "./TeamMemberPage.vue?vue&type=script&lang=js&"
import style0 from "./TeamMemberPage.vue?vue&type=style&index=0&id=7be2dccc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7be2dccc",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BackLink: require('/vercel/path0/components/general/BackLink.vue').default,AppImage: require('/vercel/path0/components/general/AppImage.vue').default,EnvelopeAlternative: require('/vercel/path0/components/icons/EnvelopeAlternative.vue').default,Phone: require('/vercel/path0/components/icons/Phone.vue').default,AppLink: require('/vercel/path0/components/general/AppLink.vue').default})
