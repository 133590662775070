
import { mapState } from 'vuex'

export default {
  props: {
    selectedCategories: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      innerSelectedCategories: [],
    }
  },

  computed: {
    ...mapState('taxonomy', ['articleCategoryTaxonomies']),
  },

  watch: {
    selectedCategories(value) {
      this.innerSelectedCategories = this.articleCategoryTaxonomies.filter(
        (category) => value.includes(category.slug)
      )
    },
  },

  mounted() {
    this.innerSelectedCategories = this.articleCategoryTaxonomies.filter(
      (category) => this.selectedCategories.includes(category.slug)
    )
  },

  methods: {
    isSelected(category) {
      return this.innerSelectedCategories.some(
        (cat) => cat.slug === category.slug
      )
    },

    selectCategory(category) {
      if (this.isSelected(category)) {
        this.innerSelectedCategories = this.innerSelectedCategories.filter(
          (cat) => cat.slug !== category.slug
        )
      } else {
        this.innerSelectedCategories.push(category)
      }

      this.$emit('filter-changed', this.innerSelectedCategories)
    },
  },
}
