import { render, staticRenderFns } from "./ArticleSearchBar.vue?vue&type=template&id=a7d6c72a&"
import script from "./ArticleSearchBar.vue?vue&type=script&lang=js&"
export * from "./ArticleSearchBar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Times: require('/vercel/path0/components/icons/Times.vue').default})
