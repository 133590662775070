import { render, staticRenderFns } from "./_.vue?vue&type=template&id=519e741b&"
import script from "./_.vue?vue&type=script&lang=js&"
export * from "./_.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductPage: require('/vercel/path0/components/pages/ProductPage.vue').default,TeamPage: require('/vercel/path0/components/pages/TeamPage.vue').default,TeamMemberPage: require('/vercel/path0/components/pages/TeamMemberPage.vue').default,ContactPage: require('/vercel/path0/components/pages/ContactPage.vue').default,ContentPageWithoutHero: require('/vercel/path0/components/pages/ContentPageWithoutHero.vue').default,ArticlesPage: require('/vercel/path0/components/pages/ArticlesPage.vue').default,ArticleDetailPage: require('/vercel/path0/components/pages/ArticleDetailPage.vue').default,ContentPage: require('/vercel/path0/components/pages/ContentPage.vue').default})
