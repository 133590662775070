
import { format, parseISO } from 'date-fns'

export default {
  props: {
    article: {
      type: Object,
      required: true,
    },

    isSmall: {
      type: Boolean,
      default: false,
    },

    bgWhite: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isHover: false,
    }
  },

  computed: {
    date() {
      return format(new Date(parseISO(this.article.date)), 'dd.MM.yyyy')
    },

    thumbnail() {
      if (this.article.image_thumbnail) {
        return this.article.image_thumbnail
      }

      return this.article.image
    },
  },
}
