
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  computed: {
    hasImage() {
      return !!this.data?.image
    },

    bgImageStyles() {
      if (!this.hasImage) {
        return null
      }
      return {
        backgroundImage: `url(${this.data.image.permalink})`,
      }
    },
  },
}
