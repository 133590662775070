
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  computed: {
    departments() {
      return this.buildDepartments(this.data.departments)
    },
  },

  methods: {
    buildDepartments(departments) {
      return departments.map((d) => d.title).join(', ')
    },
  },
}
