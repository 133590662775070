let decode
if (process.server) {
  decode = require('html-entities').decode
}

export const getTextFromHtml = (html) => {
  if (!html) return null

  if (typeof html !== 'string') {
    console.error('getTextFromHtml: html is not a string but a ' + typeof html)
    return null
  }

  if (process.server) {
    return decode(html.replace(/<[^<>]*>/g, ''))
  } else {
    const span = document.createElement('span')
    span.innerHTML = html

    return span.textContent || span.textContent
  }
}

export default {
  methods: {
    getHeadForPage(page) {
      let imageUrl = `${this.$config.appUrl}/logo.png`
      let imageAlt = page.image ? page.image.alt : 'hep Verlag Logo'
      const isProduct = page.blueprint === 'product'

      if (page.image && page.image.permalink) {
        imageUrl = page.image.permalink
      }

      if (isProduct && page.cover_image && page.cover_image.permalink) {
        imageUrl = page.cover_image.permalink
        imageAlt = `${page.title} Cover`
      }

      const head = {
        meta: [
          {
            hid: 'og:title',
            property: 'og:title',
            content: getTextFromHtml(page.page_title) || page.title,
          },

          {
            hid: 'og:url',
            property: 'og:url',
            content: this.$config.appUrl + page.url,
          },
          {
            hid: 'og:image',
            property: 'og:image',
            content: imageUrl,
          },
          {
            hid: 'og:image:alt',
            property: 'og:image:alt',
            content: imageAlt,
          },
        ],
      }

      if (isProduct) {
        const keywords = Array.isArray(page.subjects)
          ? page.subjects?.join(',')
          : ''
        head.meta.push({
          hid: 'keywords',
          name: 'keywords',
          content: keywords,
        })

        head.meta.push({
          hid: 'og:type',
          property: 'og:type',
          content: 'product',
        })
        head.meta.push({
          hid: 'book:author',
          property: 'book:author',
          content: page.authors
            ?.map((author) =>
              [author.first_name, author.last_name].filter((w) => w).join(' ')
            )
            .filter((w) => w)
            .join(', '),
        })
        head.meta.push({
          hid: 'book:isbn',
          property: 'book:isbn',
          content: page.isbn,
        })
        head.meta.push({
          hid: 'book:release_date',
          property: 'book:release_date',
          content: page.publication_month,
        })
        head.meta.push({
          hid: 'book:tag',
          property: 'book:tag',
          content: keywords,
        })
      } else if (page.blueprint === 'article') {
        const keywords = page.category?.title
        head.meta.push({
          hid: 'og:type',
          property: 'og:type',
          content: 'article',
        })
        head.meta.push({
          hid: 'keywords',
          name: 'keywords',
          content: keywords,
        })
        head.meta.push({
          hid: 'article:section',
          property: 'article:section',
          content: keywords,
        })
        head.meta.push({
          hid: 'article:author',
          property: 'article:author',
          content: 'hep Verlag AG',
        })
        head.meta.push({
          hid: 'article:published_time',
          property: 'article:published_time',
          content: page.date,
        })
      } else {
        head.meta.push({
          hid: 'og:type',
          property: 'og:type',
          content: 'website',
        })
      }

      if (page.image) {
        head.meta.push({
          hid: 'twitter:card',
          name: 'twitter:card',
          content: 'summary_large_image',
        })
      } else {
        head.meta.push({
          hid: 'twitter:card',
          name: 'twitter:card',
          content: 'summary',
        })
      }
      if (page.seo_hidden) {
        head.meta.push({ hid: 'robots', name: 'robots', content: 'noindex' })
      }
      if (page.title || page.seo_title) {
        head.title = page.seo_title || page.title
      }

      const description = getTextFromHtml(page.text)
      if (
        description ||
        page.seo_description ||
        page.lead ||
        page.description
      ) {
        head.meta.push({
          hid: 'description',
          name: 'description',
          content:
            page.seo_description ??
            description ??
            page.lead ??
            page.description,
        })
        head.meta.push({
          hid: 'og:description',
          property: 'og:description',
          content:
            page.seo_description ??
            description ??
            page.lead ??
            page.description,
        })
      }

      return head
    },
  },
}
