
export default {
  data() {
    return {
      departments: [],
      selectedDepartments: [],
    }
  },

  async fetch() {
    this.departments = await this.$store.dispatch(
      'taxonomy/loadDepartmentsTaxonomy'
    )
  },

  methods: {
    isSelected(department) {
      return this.selectedDepartments.includes(department)
    },

    selectDepartment(department) {
      if (this.isSelected(department)) {
        this.selectedDepartments = this.selectedDepartments.filter(
          (d) => d.slug !== department.slug
        )
      } else {
        this.selectedDepartments.push(department)
      }

      this.$emit('filter-changed', this.selectedDepartments)
    },
  },
}
