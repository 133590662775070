import { render, staticRenderFns } from "./TeamMemberCard.vue?vue&type=template&id=7279cc09&scoped=true&"
import script from "./TeamMemberCard.vue?vue&type=script&lang=js&"
export * from "./TeamMemberCard.vue?vue&type=script&lang=js&"
import style0 from "./TeamMemberCard.vue?vue&type=style&index=0&id=7279cc09&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7279cc09",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppImage: require('/vercel/path0/components/general/AppImage.vue').default,TransitionExpand: require('/vercel/path0/components/general/TransitionExpand.vue').default,ArrowLeftLight: require('/vercel/path0/components/icons/ArrowLeftLight.vue').default,AppLink: require('/vercel/path0/components/general/AppLink.vue').default})
