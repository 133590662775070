import { render, staticRenderFns } from "./ArticleDetailPage.vue?vue&type=template&id=33634500&"
import script from "./ArticleDetailPage.vue?vue&type=script&lang=js&"
export * from "./ArticleDetailPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BackLink: require('/vercel/path0/components/general/BackLink.vue').default,Container: require('/vercel/path0/components/general/Container.vue').default,SelectableBgColor: require('/vercel/path0/components/general/SelectableBgColor.vue').default,ComponentLoader: require('/vercel/path0/components/general/ComponentLoader.vue').default})
