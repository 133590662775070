
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  methods: {
    // vis change
    visibilityChanged(payload) {
      // register only changes which are visible = true
      if (!payload.isVisible) {
        return
      }
      const fixedNavData = {
        isVisible: payload.isVisible,
        productNavigationSlug: payload.entry.target.getAttribute(
          'product-navigation-slug'
        ),
      }

      this.$store.commit('product-fixed-nav/setFixedNavData', fixedNavData)
    },
  },
}
